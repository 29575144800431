<template>
    <div>
        <div class="qrcode_header">
            <van-nav-bar @click-left="back">
                <template #left>
                    <van-icon name="arrow-left" size="19"/>
                    返回
                </template>
                <template #right>
                    <div @click="resetValidationState" v-show="camera === 'off'">
                        重新扫码
                    </div>
                </template>
            </van-nav-bar>
        </div>
        <qrcode-stream class="main-box" ref="mainBox" :camera="camera" :torch="torchActive" @decode="onDecode"
                       @init="onInit">
            <!--      <button @click="torchActive = !torchActive" :disabled="torchNotSupported">-->
            <!--        <img :src="icon" alt="toggle torch">-->
            <!--      </button>-->
            <div class="bg-qrcode" style="display: none;" ref="bgQrcode">
                <div v-if="validationPending" class="validation-pending">
                    扫码中
                </div>
                <div v-if="validationSuccess" class="validation-success validation-pending">
                    扫码成功
                </div>
                <div class="bg-qrcode-camera" ref="bgCamera">
                    <div class="camera-box"></div>
                    <div :class="camera === 'off' ? 'animateNone' : ''" class="animate-bounce-down"></div>
                </div>
            </div>
            <div v-if="noCamera">调用摄像头失败，请手动输入条码</div>
        </qrcode-stream>
        <van-cell-group class="input-box" inset>
            <van-field
                    v-if="isShowInput || result"
                    :readonly="!isShowInput"
                    class="input-code"
                    v-model="result"
                    name="result"
                    :error="resultError"
                    :error-message="resultErrorMsg"
                    @blur="resultCheck"
                    @focus="resultCheck"
                    @change="onFieldChange"
                    placeholder="手动输入条码"
            />
            <div class="info-txt" v-if="type<3">
                <span>请扫描试卷纸上二维码。</span>
                如上方出现10位的考试二维码编号则扫码成功，点击【确认】进行试卷确认。
                <span>如扫码无法识别，点击【拍照上传】按钮对二维码拍照上传进行试卷确认。</span>
            </div>
            <div class="info-txt" v-if="type==3">
                <span>请扫描试卷袋上二维码。</span>
                如上方出现10位的考试二维码编号则扫码成功，点击【确认】进行试卷袋确认。
                <span>如扫码无法识别，点击【拍照上传】按钮对二维码拍照上传进行试卷袋确认。</span>
            </div>
            <div class="btn-area">
                <van-button color="#F5A43C" block size="normal" type="primary" @click="photoHandle">
                    拍照识别
                </van-button>
                <van-button color="#563275" block size="normal" type="primary" @click="confirmHandle">
                    确定
                </van-button>
            </div>
        </van-cell-group>
    </div>
</template>

<script>
    import {qrcodeMixin} from "mixins/index/qrcode";
    import {Toast} from "vant";
    import {codeCheck} from "store/utils";
    import {authCode, handInThePaper, getChannelStatus, paperCode} from "r/index/monitor";

    export default {
        name: "qrcode",
        mixins: [qrcodeMixin],
        data() {
            return {
                resultError: '',
                resultErrorMsg: '',
                type: 1,
                isShowInput: false,// 是否显示输入框 true-显示 false-不显示(并且禁用输入)
                codeType: 0, // 1-扫码 2-输入
            };
        },
        created() {
            this.type = this.$route.query.type;
            this.getChannelStatus();
            // let isReload = window.localStorage.getItem('monitor-qrcode-isReload');
            // Toast(111, isReload)
            // if(isReload !== '1'){
            //     window.localStorage.setItem('monitor-qrcode-isReload', '1');
            //     window.location.reload();
            // }
        },
        methods: {
            onFieldChange() {
                this.codeType = 2;
                console.log(this.codeType)
            },
            // 拍照上传
            photoHandle() {
                this.$router.replace('/photoqr?type=' + this.type);
            },
            // 判断是否显示条码输入框
            getChannelStatus() {
                const zkz = localStorage.getItem('monitor-zkz');
                const subjectUuid = localStorage.getItem("monitor-subjectUuId");
                getChannelStatus({
                    subjectUuid: subjectUuid,
                    zkzNum: zkz,
                    status: 0
                })
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) return this.$message.error(res.data.message);
                        // 0-关闭 1-打开
                        if (res.data.data == 1) {
                            this.isShowInput = true;
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            back() {
                this.$router.replace('/monitor');
            },
            // 验证输入
            resultCheck() {
                if (this.result == "" || this.result == null || this.result.length <= 0) {
                    this.resultError = true;
                    this.resultErrorMsg = "请输入条码号";
                } else if (codeCheck(this.result) === false) {
                    this.resultError = true;
                    this.resultErrorMsg = "只能输入数字、字母";
                } else {
                    this.resultError = false;
                    this.resultErrorMsg = "";
                }
            },
            // 确认
            confirmHandle() {
                if (this.result == '' || this.result == null) {
                    if (!this.isShowInput) {
                        Toast('请扫码')
                    }
                    this.resultCheck();
                    return false;
                }
                if (this.type == 1) {
                    this.requestHandle(authCode)
                } else if (this.type == 2) {
                    this.requestHandle(handInThePaper)
                } else if (this.type == 3) {
                    // 试卷袋确认
                    this.paperCodeHandle()
                }
            },
            async requestHandle(reqName) {
                const zkz = localStorage.getItem('monitor-zkz');
                const subjectUuid = localStorage.getItem("monitor-subjectUuId");
                let obj = {
                    subjectUuid: subjectUuid,
                    zkzNum: zkz,
                    code: this.result,
                    type: this.codeType
                }
                let {data: res} = await reqName(obj);
                console.log(res)
                if (res.code == 201 && this.type == 2) {
                    this.$store.state.timerUpload && window.clearInterval(this.$store.state.timerUpload);
                    this.$store.state.timerUpload = window.setInterval(() => {
                        if (this.$store.state.timeUpload > 0) {
                            this.$store.state.timeUpload--;
                            window.localStorage.setItem('monitor-timeUpload-' + zkz + '-' + subjectUuid, this.$store.state.timeUpload);
                        } else {
                            window.clearInterval(this.$store.state.timerUpload)
                        }
                    }, 1000)
                    return await this.$router.replace('/upload');
                }
                if (res.code == 202 && this.type == 2) {
                    this.$store.state.timerUpload = 0;
                    window.localStorage.setItem('monitor-timeUpload-' + zkz + '-' + subjectUuid, this.$store.state.timeUpload);
                    return await this.$router.replace('/upload');
                }
                if (res.code != 200) {
                    return Toast(res.result);
                }
                Toast(res.result);
                await this.$router.replace('/monitor');
            },
            // 试卷袋确认方法
            async paperCodeHandle() {
                let obj = {
                    code: this.result
                }
                let {data: res} = await paperCode(obj);
                console.log(res)
                if (res.code != 200) return Toast('请重新提交')
                await this.$router.replace('/monitor')
            }
        },
    }
</script>

<style scoped lang="scss">
    ::v-deep .qrcode_header {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 1000;
        width: 100%;

        .van-nav-bar {
            background-color: transparent;
        }

        .van-hairline--bottom::after {
            border-bottom-width: 0;
        }

        .van-nav-bar__text {
            color: #ffffff;
        }

        .van-nav-bar__right,
        .van-nav-bar__left,
        .van-icon-arrow-left {
            color: #ffffff !important;
        }
    }

    ::v-deep .van-icon-arrow-left {
        color: #333333 !important;
    }

    .btn-area {
        display: flex;
        flex-flow: row;

        .van-button {
            flex: 1;
            margin-left: 10px;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    ::v-deep .van-field{
        padding: 0;
        /*height: 40px;*/
        .van-field__body {
            padding: 1px 0;
            .van-field__control {
                width: 100%;
                font-size: 18px;
                padding: 5px 8px;
                border-bottom: 1px solid #ebedf0;
                color: green !important;

                &::placeholder {
                    color: #969799;
                }
            }
        }

        .van-field__error-message {
            padding: 0 8px;
        }

        &::after {
            border: 0;
        }
    }

    .info-txt {
        font-size: 13px;
        text-align: left;
        line-height: 20px;
        margin-bottom: 20px;

        span {
            color: #F5A43C;
        }
    }

    .main-box {
        height: 500px;
    }

    .input-box {
        /*margin: 0 10px;*/
        /*padding: 20px 0;*/
        margin: 0;
        padding: 20px 10px;

        .van-button {
            border-radius: 8px;
        }
    }

    .input-code {
        /*color: #1111;*/
        display: block;
        margin: 0 auto 20px;
    }

    .validation-pending {
        position: absolute;
        width: 100%;
        height: 100%;

        background-color: rgba(255, 255, 255, .8);
        text-align: center;
        font-weight: bold;
        font-size: 1.4rem;
        padding: 10px;

        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
    }

    .validation-success {
        color: green;
    }

    .main-box button {
        position: absolute;
        left: 10px;
        top: 10px;
        z-index: 999;
        color: #fff;
    }

    .bg-qrcode {
        background: rgba(0, 0, 0, 0);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .bg-qrcode-camera {
        width: 200px;
        height: 200px;
        padding: 0;
        margin: 0;
        position: relative;
        box-sizing: content-box;
        border: 100px rgba(0, 0, 0, 0.6) solid;
    }

    .camera-box {
        width: 204px;
        height: 204px;
        padding: 0;
        margin: 0;
        position: absolute;
        top: -2px;
        right: -2px;
        border: 4px rgba(255, 255, 255, 0.2) solid;
        border-radius: 4px;
    }

    @-webkit-keyframes bounce-down {
        0% {
            -webkit-transform: translateY(0px);
        }
        40% {
            -webkit-transform: translateY(80px);
        }
        80% {
            -webkit-transform: translateY(160px);
        }
        100% {
            -webkit-transform: translateY(200px);
        }
    }

    @keyframes bounce-down {
        0% {
            -webkit-transform: translateY(0px);
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.9);
        }
        40% {
            -webkit-transform: translateY(80px);
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
        }
        80% {
            -webkit-transform: translateY(160px);
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
        }
        100% {
            -webkit-transform: translateY(200px);
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.9);
        }
    }

    .animate-bounce-down {
        background: rgba(255, 255, 255, 0.4);
        width: 190px;
        margin-left: 5px;
        height: 2px;
        border-radius: 1px;
        -webkit-animation: bounce-down 3.5s linear infinite;
        animation: bounce-down 3.5s linear infinite;
    }

    .animateNone {
        display: none;
    }
</style>
