import { request } from "./network";


// rtc鉴权调用接口
export function getRtcAuth() {
  let camera = localStorage.getItem("monitor-camera");
  let subjectUuId = localStorage.getItem("monitor-subjectUuId");
  return request({
    method: "get",
    url: "alivc/getRtcAuth/"+subjectUuId+"?camera="+camera,
  });
}

//指定终端用户从频道踢出
export function removeTerminals(channelId,user_id) {
  let subjectUuId = localStorage.getItem("monitor-subjectUuId");
  return request({
    method: "get",
    url: "alivc/removeTerminals/"+channelId+'/'+subjectUuId+"?terminalIds="+user_id,
  });
}

//指定终端用户从频道踢出
export function removeUser(channelId,user_id) {
  let subjectUuId = localStorage.getItem("monitor-subjectUuId");
  let examUuid = localStorage.getItem("monitor-examUuid");
  return request({
    method: "get",
    url: "removeTerminals/"+examUuid+'/'+subjectUuId+'/'+channelId+"?terminalIds="+user_id,
  });
}

//刷新token
export function refreshToken() {
  let token = localStorage.getItem("monitor-token");
  return request({
    method: "get",
    url: "alivc/refreshToken?token="+token,
  });
}

//打印异常log
export function rtcLog(query) {
  return request({
    method: "post",
    url: "/monitor/rtc-log/add",
    params: query
  });
}

// 查看题目
export function getQuesOss(query) {
  return request({
    method: "post",
    url: "/paperOss/authOss",
    data: query
  });
}

// 查看作品提交状态
export function getCommit(query) {
  return request({
    method: "get",
    url: "/alivc/canCommit",
    params: query
  });
}

// 开考登记-条码号输入
export function authCode(query) {
  return request({
    method: "get",
    url: `/alivc/authCode/${query.subjectUuid}/${query.zkzNum}/${query.code}?type=${query.type}`
  });
}
// 交卷登记-条码号输入
export function handInThePaper(query) {
  return request({
    method: "get",
    url: `/alivc/handInThePaper/${query.subjectUuid}/${query.zkzNum}/${query.code}?type=${query.type}`
  });
}

// 判断是否可以上传作业
export function isNeedShow(subjectUuid, zkzNum) {
  return request({
    method: "get",
    url: `/alivc/isNeedShow/${zkzNum}/${subjectUuid}`,
  });
}

// status-0 判断是否显示条码输入框 0-关闭 1-打开
// status-1 判断是否可以提交作品答案 0-关闭 1-打开
export function getChannelStatus(params) {
  return request({
    method: "get",
    url: '/system/getChannelStatus',
    params
  });
}

// 试卷袋确认
export function paperCode(params) {
  let zkzNum = localStorage.getItem('monitor-zkz');
  let subjectUuId = localStorage.getItem("monitor-subjectUuId");
  return request({
    method: "get",
    url: '/paperCode/'+subjectUuId+'/'+zkzNum,
    params
  });
}
