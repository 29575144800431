//权限相关
import {frontOrBackCameras} from "r/index/position";
import { Dialog,Toast,Button,Field, CellGroup } from 'vant';
import offImg from "a/images/index/flash-off.svg"
import onImg from "a/images/index/flash-on.svg"
import switchIcon from "a/images/index/camera-switch.svg"
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'

export const qrcodeMixin = {
    components: { QrcodeStream },

    data() {
        return {
            isValid: undefined,
            offImg:offImg,
            switchIcon:switchIcon,
            onImg:onImg,
            torchActive: false,
            torchNotSupported: false,
            camera: 'auto',
            noCamera: false,
            result: null,
        };
    },
    computed: {
        icon() {
            if (this.torchActive)
                return offImg
            else
                return onImg
        },
        validationPending () {
            return this.isValid === undefined
                && this.camera === 'off'
        },
        validationSuccess () {
            return this.isValid === true
        }
    },
    created() {
    },
    methods: {
        async onInit (promise) {
            try {
                await promise;
                localStorage.removeItem("hasReload");
                this.setBorder();
            } catch (error) {
                let hasReload = localStorage.getItem("hasReload");
                if(!hasReload){
                    localStorage.setItem("hasReload","1");
                    location.reload();
                }
                const cameraMissingError = error.name === 'OverconstrainedError'
                if(error.name === "NotFoundError" || cameraMissingError){
                    this.noCamera = true
                }
                let bgQrcode = this.$refs.bgQrcode;
                bgQrcode.setAttribute("style","display:none");
                console.error(error)
            }
        },
        resetValidationState () {
            this.isValid = undefined;
            this.result = null;
            this.turnCameraOn();
        },
        setBorder(){
            let bgQrcode = this.$refs.bgQrcode;
            bgQrcode.setAttribute("style","display:block");
            let bgCamera = this.$refs.bgCamera;
            let clientWidth = bgQrcode.clientWidth;
            let clientHeight = bgQrcode.clientHeight;

            let width = (clientWidth-200)/2;
            let height = (clientHeight-200)/2;
            bgCamera.setAttribute("style","border-left-width:"+width+"px;border-right-width:"+width+"px;border-top-width:"+height+"px;border-bottom-width:"+height+"px");
        },
        switchCamera () {
            switch (this.camera) {
                case 'front':
                    this.camera = 'rear'
                    break
                case 'rear':
                    this.camera = 'front'
                    break
            }
        },
        async onDecode (content) {
            this.result = content.slice(content.length-10, content.length)
            this.codeType = 1;
            this.resultCheck();
            this.turnCameraOff()
            // await this.timeout(2000)
            this.isValid = true;
        },

        turnCameraOn () {
            this.camera = 'auto'
        },

        turnCameraOff () {
            this.camera = 'off'
        },

        timeout (ms) {
            return new Promise(resolve => {
                window.setTimeout(resolve, ms)
            })
        }
    },
    mounted() {
        // this.onInit();
    },
    activated() {
    },
};
