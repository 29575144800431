import { request } from "./network";


// 选择前置还是后置摄像头
export function frontOrBackCameras(camera) {
  let subjectUuId = localStorage.getItem("monitor-subjectUuId");

  return request({
    method: "get",
    url: "alivc/frontOrBackCameras?camera="+camera+'&subjectUuid='+subjectUuId
  });
}
